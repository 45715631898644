<template>
  <content-layout :loading="loading" v-if="$route.name === 'super-admin-home'">
    <div class="px-6">
      <template v-for="groupItem in groups">
        <div class="text-h5 my-6" :key="groupItem.title">
          {{ groupItem.title }}
        </div>
        <v-row :key="groupItem.id">
          <v-col
            cols="auto"
            v-for="item in getDisplayedCards(groupItem)"
            :key="item.title"
          >
            <cz-dashboard-card
              :image-src="item.image"
              :title="item.title"
              :subtitle="item.subtitle"
              :count="item.count && item.count()"
              :clickable="item.handler || item.to ? true : false"
              @click="
                item.handler
                  ? item.handler()
                  : item.to
                  ? $router.push(item.to)
                  : null
              "
            />
          </v-col>
        </v-row>
      </template>
      <new-customer-dialog
        v-model="newCustomerDialog.show"
        v-if="newCustomerDialog.show"
        v-bind="newCustomerDialog"
        @close="newCustomerDialog.show = false"
        @on-create="createNewCustomer"
      />
      <create-super-admin-dialog
        v-model="createSuperAdminUserDialog.show"
        v-if="createSuperAdminUserDialog.show"
        v-bind="createSuperAdminUserDialog"
        @close="createSuperAdminUserDialog.show = false"
        @on-create="createNewUser"
      />
    </div>
    <invite-user-dialog
      v-if="inviteUserDialog.show"
      v-model="inviteUserDialog.show"
      v-bind="inviteUserDialog"
      @close="inviteUserDialog.show = false"
      @on-invite="inviteUser"
    />

    <modify-vendor-id-dialog
      v-if="modifyVendorIdDialog.show"
      v-model="modifyVendorIdDialog.show"
      v-bind="modifyVendorIdDialog"
      @close="modifyVendorIdDialog.show = false"
      @success="onModifyVendorIdSuccess"
      @failed="onModifyVendorIdFailed"
    />
  </content-layout>
  <router-view v-else />
</template>

<script>
import vendorApprovalImage from '@/assets/illustrations/vendor-approval.svg';
import vendorPendingApprovalImage from '@/assets/illustrations/pending-approval.svg';
import vendorsReturnedImage from '@/assets/illustrations/vendors-returned.svg';
import vendorsRejectedImage from '@/assets/illustrations/vendors-rejected.svg';
import vendorsDraftImage from '@/assets/illustrations/vendors-draft.svg';
import vendorsSearch from '@/assets/illustrations/vendors-search.svg';
import vendorCategoriesImage from '@/assets/illustrations/categories.svg';
import newCustomerImage from '@/assets/illustrations/new-customer.svg';
import editVendorImage from '@/assets/illustrations/edit-vendor.svg';
import customersListImage from '@/assets/illustrations/view-customers-list.svg';
import cleanupImage from '@/assets/illustrations/delete.svg';
import ContentLayout from '@/layouts/ContentLayout.vue';
import userInviteImage from '@/assets/illustrations/user-invite.svg';
import workflowManagementImage from '@/assets/illustrations/workflow-management.svg';

import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import {
  getVendorsStatusCount,
  createCustomer,
  superAdminCreateUser,
  cleanup,
  createInvitation
} from '@/core/api';
import cloneDeep from 'lodash.clonedeep';
import { CzDashboardCard } from '@/components';
import InvitationType from '@/shared/types/InvitationType';

export default {
  name: 'SuperAdminHome',
  components: {
    ContentLayout,
    NewCustomerDialog: () => import('../components/NewCustomerDialog.vue'),
    InviteUserDialog: () =>
      import('@/domain/customer/components/InviteUserDialog.vue'),
    CreateSuperAdminDialog: () =>
      import('../components/CreateSuperAdminUserDialog.vue'),
    ModifyVendorIdDialog: () =>
      import('../components/ModifyVendorIdDialog.vue'),
    CzDashboardCard
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapFields('auth', ['accountId'])
  },
  data() {
    return {
      loading: false,
      statusCounts: null,
      newCustomerDialog: {
        show: false,
        loading: false
      },
      inviteUserDialog: {
        show: false,
        loading: false,
        allowedInvitationTypes: [InvitationType.VendorAdmin]
      },
      createSuperAdminUserDialog: {
        show: false,
        width: 500,
        loading: false
      },
      modifyVendorIdDialog: {
        show: false
      },
      groups: [
        {
          id: 'vendors',
          title: this.$t('admin.manageVendorsTitle'),
          cards: [
            {
              id: 'pending',
              title: this.$t('admin.pendingVendors'),
              subtitle: '',
              visible: true,
              image: vendorPendingApprovalImage,
              value: 'pending_approval',
              to: {
                name: 'manage-vendors',
                params: {
                  status: 'pending_approval'
                }
              }
            },
            {
              id: 'approved',
              title: this.$t('admin.approvedVendors'),
              subtitle: '',
              visible: true,
              image: vendorApprovalImage,
              value: 'active',
              to: {
                name: 'manage-vendors',
                params: {
                  status: 'active'
                }
              }
            },
            {
              id: 'returned',
              title: this.$t('admin.returnedVendors'),
              subtitle: '',
              visible: true,
              image: vendorsReturnedImage,
              value: 'returned',
              to: {
                name: 'manage-vendors',
                params: {
                  status: 'returned'
                }
              }
            },
            {
              id: 'rejected',
              title: this.$t('admin.rejectedVendors'),
              subtitle: '',
              visible: true,
              image: vendorsRejectedImage,
              value: 'blocked',
              to: {
                name: 'manage-vendors',
                params: {
                  status: 'blocked'
                }
              }
            },
            {
              id: 'draft',
              title: this.$t('admin.draftVendors'),
              subtitle: '',
              visible: true,
              image: vendorsDraftImage,
              value: 'draft',
              to: {
                name: 'manage-vendors',
                params: {
                  status: 'draft'
                }
              }
            },
            {
              id: 'invite_vendor',
              title: this.$t('admin.inviteVendor'),
              subtitle: this.$t('admin.inviteVendorInfo'),
              visible: true,
              image: userInviteImage,
              handler: () => {
                this.inviteUserDialog.show = true;
              }
            },
            {
              id: 'modify_vendor_id',
              title: this.$t('admin.modifyVendorId'),
              subtitle: this.$t('admin.modifyVendorIdInfo'),
              visible: true,
              image: editVendorImage,
              handler: () => {
                this.modifyVendorIdDialog.show = true;
              }
            },
            {
              id: 'vendors-list',
              title: this.$t('customer.vendorsList'),
              subtitle: this.$t('customer.vendorsListInfo'),
              image: vendorsSearch,
              visible: true,
              to: {
                name: 'super-admin-vendors-lookup',
                params: {
                  selectedTab: 0
                }
              }
            },
            {
              id: 'vendor-categories',
              title: this.$t('admin.vendorCategoriesTitle'),
              subtitle: this.$t('admin.vendorCategoriesSubtitle'),
              image: vendorCategoriesImage,
              visible: true,
              to: {
                name: 'super-admin-vendors-categories'
              }
            }
          ]
        },
        {
          id: 'customers',
          title: this.$t('admin.manageCustomersTitle'),
          cards: [
            {
              id: 'customers-list',
              title: this.$t('admin.customersManagement'),
              subtitle: this.$t('admin.customerManagementInfo'),
              image: customersListImage,
              visible: true,
              to: {
                name: 'customers-list'
              }
            },
            {
              id: 'new-customer',
              title: this.$t('admin.createNewCustomer'),
              subtitle: this.$t('admin.createNewCustomerInfo'),
              image: newCustomerImage,
              visible: true,
              handler: () => {
                this.newCustomerDialog.show = true;
              }
            },
            {
              id: 'customers-workflow-definition',
              title: this.$t('admin.workflowManagementTitle'),
              subtitle: this.$t('admin.workflowManagementInfo'),
              image: workflowManagementImage,
              visible: true,
              to: {
                name: 'super-admin-workflow-management'
              }
            }
          ]
        },
        {
          id: 'users',
          title: this.$t('admin.adminUsers'),
          cards: [
            {
              title: this.$t('admin.createSuperAdminUserTitle'),
              subtitle: this.$t('admin.createSuperAdminUserSubtitle'),
              image: newCustomerImage,
              visible: true,
              handler: () => {
                this.createSuperAdminUserDialog.show = true;
              }
            }
            // {
            //   title: 'מחיקת נתונים',
            //   subtitle: 'מחק את כל הנתונים והתחל מחדש',
            //   image: cleanupImage,
            //   visible: process.env.VUE_APP_MODE !== 'production' ? true : false,
            //   handler: async () => {
            //     try {
            //       this.loading = true;
            //       await cleanup({});
            //       alert('כל הנתונים נמחקו בהצלחה');
            //     } catch (err) {
            //       alert(err.message);
            //     } finally {
            //       this.loading = false;
            //     }
            //   }
            // }
          ]
        }
      ]
    };
  },
  methods: {
    ...mapActions('vendor', ['loadCategories']),
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    async loadData() {
      try {
        this.loading = true;
        this.statusCounts = await getVendorsStatusCount();
        await this.loadCategories();
      } finally {
        this.loading = false;
      }
    },
    getDisplayedCards(groupItem) {
      return groupItem.cards.filter((card) => card.visible);
    },
    mapStatusCountsToCards() {
      if (!this.statusCounts) {
        return;
      }

      // get vendors group cards
      const _groups = cloneDeep(this.groups);
      const { cards } = _groups.find((item) => item.id === 'vendors');
      Object.keys(this.statusCounts).forEach((key) => {
        const card = cards.find((item) => item.value === key);
        if (card) {
          card.subtitle = `${this.statusCounts[key]} ${card.title}`;
        }
      });

      this.groups = _groups;
    },
    onCardClicked(value) {
      this.$router.push({
        name: 'manage-vendors',
        params: {
          status: value
        }
      });
    },
    async createNewCustomer(customer) {
      try {
        this.newCustomerDialog.loading = true;
        await createCustomer({
          ...customer,
          accountType: 'customer'
        });
        this.newCustomerDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('admin.newCustomerCreatedSuccessMessage', [
            customer.accountOwnerEmail
          ])
        );
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.newCustomerDialog.loading = false;
      }
    },
    async createNewUser(user) {
      try {
        this.createSuperAdminUserDialog.loading = true;
        await superAdminCreateUser(user);
        this.createSuperAdminUserDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('admin.newUserCreatedSuccessMessage')
        );
      } finally {
        this.createSuperAdminUserDialog.loading = false;
      }
    },
    async inviteUser(data) {
      try {
        this.inviteUserDialog.loading = true;
        await createInvitation({
          inviteeEmail: data.email,
          assignedRoles: [data.role.role],
          invitationType: data.role.invitationType,
          accountId: this.accountId
        });
        this.showSuccessToastMessage(
          this.$t('invite.inviteSentSuccess', [data.email])
        );
        this.inviteUserDialog.show = false;
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.inviteUserDialog.loading = false;
      }
    },
    onModifyVendorIdSuccess() {
      this.showSuccessToastMessage(this.$t('admin.modifyVendorIdSuccess'));
      this.modifyVendorIdDialog.show = false;
    },
    onModifyVendorIdFailed(message) {
      this.showErrorToastMessage(message);
    }
  },
  watch: {
    statusCounts: {
      handler() {
        this.mapStatusCountsToCards();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
